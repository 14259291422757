import Swiper, {Navigation, Pagination} from 'swiper';

new Swiper('.portfolio-swiper', {
    modules: [Pagination],
    pagination: {
        el: '.portfolio-swiper .swiper-pagination', clickable: true,
    },
    loop: true,
});

new Swiper('.reviews-swiper', {
    modules: [Navigation],
    navigation: {
        nextEl: '.reviews-swiper .swiper-button-next', prevEl: '.reviews-swiper .swiper-button-prev',
    },
    spaceBetween: 20,
    breakpoints: {
        // when window width is >= 1080
        1080: {
            slidesPerView: 3,
        },
        // when window width is >= 720
        720: {
            slidesPerView: 2,
        },
    },
    loop: true,
});

const header = document.getElementById('header');
const stickyClass = 'sticky';

window.onscroll = () => {
    if (window.scrollY > header.offsetTop) {
        header.classList.add(stickyClass);
    } else {
        header.classList.remove(stickyClass);
    }
};

const menu = document.getElementById('menu');
const menuOpen = document.getElementById('menu-open');
const menuClose = document.getElementById('menu-close');
const menuNavList = menu.querySelector('.nav-list');

const openMenu = () => {
    menu.classList.add('opened');
    disablePageScroll();
};

const closeMenu = () => {
    menu.classList.remove('opened');
    enablePageScroll();
};

menuOpen.addEventListener('click', openMenu);
menuClose.addEventListener('click', closeMenu);
menuNavList.addEventListener('click', closeMenu);

function disablePageScroll() {
    document.body.style.overflow = 'hidden';
}

function enablePageScroll() {
    document.body.style.overflow = '';
}

/** TODO
 *   1) SEO
 *      1.1) Проверить через https://webmaster.yandex.ru
 *      1.2) Проверить через https://pagespeed.web.dev/
 *   2) Метрики
 *      2.1) Настройка счетчика https://www.youtube.com/watch?v=iiQhXsci-00
 *   3) Добавить призыв к действию (Кнопка заказать)
 */
